import { ApolloError, gql, useQuery } from "@apollo/client"
import { GroupSimple } from "types"

import { SimpleGroupFragment } from "./fragments/groupFragment"

export const MY_GROUPS_SIMPLE_QUERY = gql`
  ${SimpleGroupFragment}
  query getMyGroupsSimple($userId: ID) {
    myGroupsSimple(userId: $userId) {
      ...SimpleGroupFragment
    }
  }
`

export default function useMyGroupsSimple(userId?: string): {
  loading: boolean
  error?: ApolloError
  groups: GroupSimple[]
} {
  const { loading, error, data } = useQuery(MY_GROUPS_SIMPLE_QUERY, {
    variables: { userId },
  })
  return {
    groups: data?.myGroupsSimple || [],
    loading,
    error,
  }
}
