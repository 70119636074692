import useWorkingModesPerDay from "graphql/stats/useWorkingModesPerDay"
import React, { useEffect, useState } from "react"
import { GroupSimple, UserBase } from "types"
import UTCDate from "utils/UTCDate"

interface PropsType {
  users: UserBase[]
  groups: GroupSimple[]
  from: UTCDate
  to: UTCDate
  includeWeekends: boolean
  updateWorkingModesPerDay: (
    workingModesPerDay: {
      day: string
      workingMode: string
      value: number
    }[]
  ) => void
}

export default function WorkingModesPerDay({
  users,
  groups,
  from,
  to,
  includeWeekends,
  updateWorkingModesPerDay,
}: PropsType) {
  const { workingModesPerDay, loading, error } = useWorkingModesPerDay({
    userIds: users.map((u) => u.id),
    groupIds: groups.map((g) => g.id),
    from,
    to,
    includeWeekends,
  })

  const [previousUsers, setPreviousUsers] = useState<UserBase[] | undefined>(
    undefined
  )
  const [previousGroups, setPreviousGroups] = useState<
    GroupSimple[] | undefined
  >(undefined)

  const [previousTimeFrame, setPreviousTimeFrame] = useState<
    { from: UTCDate; to: UTCDate; includeWeekends: boolean } | undefined
  >(undefined)

  useEffect(() => {
    if (error || loading) return

    const timeFrameHasChanged =
      previousTimeFrame?.from.getTime() !== from.getTime() ||
      previousTimeFrame?.to.getTime() !== to.getTime() ||
      previousTimeFrame?.includeWeekends !== includeWeekends

    const doesGroupsMatch = (a: GroupSimple[], b: GroupSimple[]) => {
      for (const groupFromA of a) {
        if (a.length !== b.length) return false
        if (!b.find((groupFromB) => groupFromA.id === groupFromB.id))
          return false
        return true
      }
    }

    const doesUsersMatch = (a: UserBase[], b: UserBase[]) => {
      for (const userFromA of a) {
        if (a.length !== b.length) return false
        if (!b.find((userFromB) => userFromA.id === userFromB.id)) return false
        return true
      }
    }

    const groupsHaveChanges =
      previousGroups === undefined || !doesGroupsMatch(groups, previousGroups)

    const usersHaveChanges =
      previousUsers === undefined || !doesUsersMatch(users, previousUsers)

    if (groupsHaveChanges || usersHaveChanges || timeFrameHasChanged) {
      updateWorkingModesPerDay(workingModesPerDay)
      setPreviousGroups(groups)
      setPreviousUsers(users)
      setPreviousTimeFrame({ from, to, includeWeekends })
    }
  }, [
    workingModesPerDay,
    loading,
    error,
    users,
    groups,
    from,
    to,
    includeWeekends,
    updateWorkingModesPerDay,
    previousGroups,
    previousUsers,
    previousTimeFrame,
  ])

  useEffect(() => {
    return () => updateWorkingModesPerDay([])
    // eslint-disable-next-line
  }, [])
  return <></>
}
