import LocationIcon from "components/LocationIcon"
import { UserAvatarFromId } from "components/UserAvatar/UserAvatar"
import useAllGroups from "graphql/groups/useAllGroups"
import useUser from "graphql/users/useUser"
import useCompanyFlag from "hooks/useCompanyFlag"
import useCompanyFlags from "hooks/useCompanyFlags"
import useTranslate from "intl/useTranslate"
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { Group, GroupSimple, User, UserBase } from "types"
import { colors, P16 } from "ui"
import { Building, EyeEmpty, EyeOff, Group as GroupIcon } from "ui/icons"
import getTeamDisplayName from "utils/getTeamDisplayName"
import getUserDisplayName from "utils/getUserDisplayName"
import LocationTree, { getChildrenLocations } from "utils/LocationTree"

import SearchForm from "./SearchForm/SearchForm"

const Layout = styled.div``

const List = styled.ul``

const Item = styled.li`
  display: flex;
  gap: 16px;
  padding: 12px 8px;
  border-bottom: solid 1px ${colors.grey3};

  cursor: pointer;

  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  svg {
    width: 20px;
    height: 20px;
  }

  > svg:last-child {
    margin-left: auto;
  }
`

interface StatsSample {
  users: UserBase[]
  groups: GroupSimple[]
  locations: LocationTree[]
}

interface PropsType {
  data: StatsSample
  hideDefaults?: boolean
  showAllLocationsBar?: boolean
  cannotCombineAllLocationsWithSelection?: boolean
  onChange: (sample: StatsSample) => void
  setShowAllLocationsBar?: React.Dispatch<React.SetStateAction<boolean>>
}

function getLargestGroupThatUserIsPartOf(
  user: User | null,
  data: StatsSample,
  allGroups: Group[]
) {
  if (!user) return null
  const groups = allGroups.filter((g) => {
    return g.users.find((u) => u.id === user.id)
  })
  let count = 0
  let largestGroup: Group | null = null
  groups.forEach((g) => {
    if (g.users.length > count) {
      count = g.users.length
      largestGroup = g
    }
  })
  return data.groups.find((g) => g.id === largestGroup?.id)
}

export default function SamplePicker({
  data,
  hideDefaults = false,
  showAllLocationsBar,
  cannotCombineAllLocationsWithSelection,
  onChange,
  setShowAllLocationsBar,
}: PropsType) {
  const t = useTranslate()
  const { companyFlags } = useCompanyFlags()

  const { user: me } = useUser()

  const { groups } = useAllGroups()

  const hideEveryoneGroup = useCompanyFlag("hideEveryoneGroup", false)

  const myTeam = data.groups.find((g) => g.id === "myteam")
  const myManagerTeam = data.groups.find((g) => g.id === "mymanagerteam")
  const everyoneGroup = hideEveryoneGroup
    ? undefined
    : data.groups.find((g) => g.id === "everyone")

  const sortedData: StatsSample = {
    users: !hideDefaults
      ? [me, ...data.users.filter((u) => u.id !== me?.id)].filter(
          (x): x is UserBase => x !== null
        )
      : [...data.users],
    groups: !hideDefaults
      ? [
          myTeam,
          myManagerTeam,
          everyoneGroup,
          ...data.groups.filter(
            (g) =>
              g.id !== "myteam" &&
              g.id !== "mymanagerteam" &&
              g.id !== "everyone"
          ),
        ].filter((x): x is GroupSimple => x !== undefined)
      : [...data.groups],
    locations: getChildrenLocations(data.locations, false),
  }

  const [visibleItems, setVisibleItems] = useState<StatsSample>({
    ...sortedData,
  })

  const defaultGroup = everyoneGroup
    ? everyoneGroup
    : getLargestGroupThatUserIsPartOf(me, data, groups)
  const defaultGroups = defaultGroup ? [defaultGroup] : []

  const [selectedItems, setSelectedItems] = useState<StatsSample>({
    users: [],
    // groups: [myTeam].filter((x): x is GroupSimple => x !== undefined),
    groups: everyoneGroup ? [everyoneGroup] : defaultGroups,
    locations: [],
  })

  const addSelectedItem = (
    item: UserBase | GroupSimple | LocationTree,
    type: keyof StatsSample
  ) => {
    switch (type) {
      case "users":
        const filteredUsersSelection = [
          ...selectedItems.users.filter((u) => u.id !== item.id),
        ] as UserBase[]

        if (filteredUsersSelection.length < selectedItems.users.length) {
          const newSelection = {
            ...selectedItems,
            users: filteredUsersSelection,
          }
          setSelectedItems(newSelection)
          onChange(newSelection)
        } else {
          const newSelection = {
            ...selectedItems,
            users: [...filteredUsersSelection, item] as UserBase[],
          }
          setSelectedItems(newSelection)
          onChange(newSelection)
        }
        break

      case "groups":
        const filteredGroupsSelection = [
          ...selectedItems.groups.filter((g) => g.id !== item.id),
        ] as GroupSimple[]

        if (filteredGroupsSelection.length < selectedItems.groups.length) {
          const newSelection = {
            ...selectedItems,
            groups: filteredGroupsSelection,
          }
          setSelectedItems(newSelection)
          onChange(newSelection)
        } else {
          const newSelection = {
            ...selectedItems,
            groups: [...filteredGroupsSelection, item] as GroupSimple[],
          }
          setSelectedItems(newSelection)
          onChange(newSelection)
        }
        break

      case "locations":
        const filteredLocationsSelection = [
          ...selectedItems.locations.filter((l) => l.id !== item.id),
        ] as LocationTree[]

        if (
          filteredLocationsSelection.length < selectedItems.locations.length
        ) {
          const newSelection = {
            ...selectedItems,
            locations: filteredLocationsSelection,
          }
          setSelectedItems(newSelection)
          onChange(newSelection)
        } else {
          const newSelection = {
            ...selectedItems,
            locations: [...filteredLocationsSelection, item] as LocationTree[],
          }
          setSelectedItems(newSelection)
          onChange(newSelection)
        }
        break
    }
  }

  const debounce = (fn: (...args: any[]) => void, ms = 300) => {
    let timeoutId: ReturnType<typeof setTimeout>
    return function (this: any, ...args: any[]) {
      clearTimeout(timeoutId)
      timeoutId = setTimeout(() => fn.apply(this, args), ms)
    }
  }

  const filterSelection = (str: string) => {
    const users = sortedData.users.filter((u) =>
      u.name.toLowerCase().includes(str.toLowerCase())
    )
    const groups = sortedData.groups.filter((g) =>
      g.name.toLowerCase().includes(str.toLowerCase())
    )
    const locations = sortedData.locations.filter((l) =>
      l.name.toLowerCase().includes(str.toLowerCase())
    )

    setVisibleItems({
      users,
      groups,
      locations,
    })
  }

  const canShowAllLocationsBar =
    showAllLocationsBar &&
    (!cannotCombineAllLocationsWithSelection ||
      selectedItems.locations.length === 0)

  const getIcon = (
    type: "users" | "groups" | "locations",
    item: UserBase | GroupSimple | LocationTree
  ) => {
    switch (type) {
      case "users":
        return <UserAvatarFromId id={item.id} />
      case "groups":
        return <GroupIcon />
      case "locations":
        return item instanceof LocationTree ? (
          <LocationIcon location={item} />
        ) : (
          <Building />
        )
    }
  }

  const getItemName = (
    type: "users" | "groups" | "locations",
    item: UserBase | GroupSimple | LocationTree,
    me: User
  ) => {
    switch (type) {
      case "users":
        if (item.id === me.id) return t("Me")
        return getUserDisplayName(item as UserBase, me.company.flags)
      case "groups":
        return getTeamDisplayName(item as GroupSimple, companyFlags, t)
      default:
        return item.name
    }
  }

  useEffect(() => {
    onChange(selectedItems)

    // eslint-disable-next-line
  }, [])

  if (me === null) return <></>

  return (
    <Layout>
      <SearchForm
        onChange={debounce(filterSelection)}
        onReset={() => {
          setVisibleItems(sortedData)
        }}
      />
      <List>
        {showAllLocationsBar !== undefined && (
          <Item
            onClick={() => {
              if (setShowAllLocationsBar) {
                setShowAllLocationsBar((prev) => !prev)
                if (cannotCombineAllLocationsWithSelection) {
                  const prevSelection = { ...selectedItems }
                  setSelectedItems((prev) => ({ ...prev, locations: [] }))
                  onChange({ ...prevSelection, locations: [] })
                }
              }
            }}
          >
            <P16>{t("All locations")}</P16>
            {canShowAllLocationsBar ? <EyeEmpty /> : <EyeOff />}
          </Item>
        )}
        {(["users", "groups", "locations"] as Array<keyof StatsSample>).map(
          (key) =>
            visibleItems[`${key}`].map((item) => {
              let selected = false
              switch (key) {
                case "users":
                  if (selectedItems.users.find((u) => u.id === item.id))
                    selected = true
                  break

                case "groups":
                  if (selectedItems.groups.find((g) => g.id === item.id))
                    selected = true
                  break

                case "locations":
                  if (selectedItems.locations.find((l) => l.id === item.id))
                    selected = true
                  break

                default:
                  selected = false
                  break
              }
              return (
                <Item
                  key={`data-item-${item.id}`}
                  onClick={() => {
                    addSelectedItem(item, key)
                    if (
                      cannotCombineAllLocationsWithSelection &&
                      setShowAllLocationsBar
                    )
                      setShowAllLocationsBar(false)
                  }}
                >
                  {getIcon(key, item)}
                  <P16>{getItemName(key, item, me)}</P16>
                  {selected ? <EyeEmpty /> : <EyeOff />}
                </Item>
              )
            })
        )}
      </List>
    </Layout>
  )
}
