import LoaderOrErrorComponent from "components/LoaderOrErrorComponent"
import useAllGroups from "graphql/groups/useAllGroups"
import useMyGroupsSimple from "graphql/groups/useMyGroupsSimple"
import uniqBy from "lodash.uniqby"
import React, { useState } from "react"
import { GroupSimple, UserBase } from "types"
import LocationTree from "utils/LocationTree"
import UTCDate, { UTCDateClampType } from "utils/UTCDate"

import DatePicker from "../components/DatePicker"
import {
  ChartLayout,
  DataLayout,
  Layout,
  SampleList,
} from "../components/Layout"
import SamplePicker from "../components/SamplePicker/SamplePicker"
import WeekDaysChart from "./WeekDaysChart/WeekDaysChart"

interface PropsType {
  from: UTCDate
  to: UTCDate
  selectedPeriod: UTCDateClampType | "personalized"
  enableWeekends: boolean
  pageHeaderHeight: { header?: number; tabsHeader?: number }
  editSelectedPeriod: (string: UTCDateClampType | "personalized") => void
  editFromDate: (date: UTCDate) => void
  editToDate: (date: UTCDate) => void
  editEnableWeekends: (bool: boolean) => void
}

export default function WeekDays({
  from,
  to,
  selectedPeriod,
  enableWeekends,
  pageHeaderHeight,
  editEnableWeekends,
  editSelectedPeriod,
  editFromDate,
  editToDate,
}: PropsType) {
  const alreadyOccupiedHeight =
    pageHeaderHeight.header !== undefined &&
    pageHeaderHeight.tabsHeader !== undefined
      ? pageHeaderHeight.header + pageHeaderHeight.tabsHeader
      : undefined

  const { groups: myGroupsSimple, loading, error } = useMyGroupsSimple()
  const {
    groups: allGroups,
    loading: loadingAllGroups,
    error: errorAllGroups,
  } = useAllGroups()

  const [selectedGroups, setSelectedGroups] = useState<GroupSimple[]>([])
  const [selectedUsers, setSelectedUsers] = useState<UserBase[]>([])

  if (loading || loadingAllGroups || error || errorAllGroups)
    return (
      <LoaderOrErrorComponent
        loading={loading || loadingAllGroups}
        error={error || errorAllGroups}
      />
    )
  return (
    <Layout>
      <SampleList alreadyOccupiedHeight={alreadyOccupiedHeight}>
        <SamplePicker
          data={{
            users: [],
            groups: uniqBy([...myGroupsSimple, ...allGroups], "id"),
            locations: [],
          }}
          onChange={(sample: {
            users: UserBase[]
            groups: GroupSimple[]
            locations: LocationTree[]
          }) => {
            setSelectedGroups(sample.groups)
            setSelectedUsers(sample.users)
          }}
        />
      </SampleList>
      <DataLayout>
        <div>
          <DatePicker
            from={from}
            to={to}
            selectedPeriod={selectedPeriod}
            enableWeekends={enableWeekends}
            editEnableWeekends={editEnableWeekends}
            editSelectedPeriod={editSelectedPeriod}
            editFromDate={editFromDate}
            editToDate={editToDate}
          />
        </div>
        <ChartLayout alreadyOccupiedHeight={alreadyOccupiedHeight}>
          <WeekDaysChart
            users={selectedUsers}
            groups={selectedGroups}
            from={from}
            to={to}
            includeWeekends={enableWeekends}
          />
        </ChartLayout>
      </DataLayout>
    </Layout>
  )
}
